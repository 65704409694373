import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["form"];
  static values = {
    /* The types of element that is the parent container */
    container: { type: String, default: "td, th" },
  };

  connect() {
    if (this.hasFormTarget) {
      this.form = this.formTarget;
    } else if (this.element.tagName.toUpperCase() == "FORM") {
      this.form = this.element;
    } else {
      console.error("Batchables controller must specify form target or be on a form");
    }
    this.allIdsCheckbox = document.getElementById("all-ids");
    if (!this.allIdsCheckbox) {
      console.error("Batchables *controller* requires an all ids checkbox -- otherwise maybe you just need a batch form?");
    }
    this.setShiftCheckboxSource(false);
    if (this.data.get("initiallyChecked") === "prespecified") { // see BatchHelper::PRESPECIFIED
      this.checkAllPrespecifiedIds();
    }
    this.setCheckboxCallbacks();
    this.ensureStateOfAllIdsCheckbox();
    this.addDatatablesCallback();
  }

  teardown() {
    this.removeDatatablesCallback();
  }

  setCheckboxCallbacks() {
    for (let checkbox of this.idCheckboxes()) {
      checkbox.addEventListener("click", (event) => {
        this._checkboxClicked(event, checkbox);
      });
      checkbox.addEventListener("mouseover", (event) => {
        this._checkboxHover(event, checkbox, true);
      });
      checkbox.addEventListener("mouseleave", (event) => {
        this._checkboxHover(event, checkbox, false);
      });
      checkbox.addEventListener("change", () => {
        this.ensureStateOfAllIdsCheckbox();
      });
    }
    this.allIdsCheckbox.addEventListener("change", () => {
      this.addIdsCheckboxChanged();
    });
  }

  addDatatablesCallback() {
    $(this.element).on( "draw.dt",  () => {
      this.ensureStateOfAllIdsCheckbox();
    } );
  }

  removeDatatablesCallback() {
    $(this.element).off( "draw.dt");
  }
  _checkboxClicked(event, checkbox) {
    if (event.shiftKey) {
      for (let target of this._shiftTargets(checkbox)) {
        target.checked = true;
      }
    } else {
      if (checkbox.checked) {
        this.setShiftCheckboxSource(checkbox);
      } else {
        this.setShiftCheckboxSource(false);
      }
    }
  }

  setShiftCheckboxSource(checkbox) {
    if (this.shiftCheckboxSource) {
      this.shiftCheckboxSource.closest(this.containerValue).classList.remove("id-checkbox--shift-checkbox-source");
    }
    if (checkbox) {
      this.shiftCheckboxSource = checkbox;
      checkbox.closest(this.containerValue).classList.add("id-checkbox--shift-checkbox-source");
    } else {
      this.shiftCheckboxSource = undefined;
    }
  }

  _checkboxHover(event, checkbox, hovering) {
    // polyfill available but not around: https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
    if (!Element.prototype.closest) return;

    if (hovering && this.shiftCheckboxSource && this.shiftCheckboxSource !== checkbox && event.shiftKey) {
      for (let target of this._shiftTargets(checkbox)) {
        target.closest(this.containerValue).classList.add("id-checkbox--shift-hover");
      }
    } else {
      for (let target of this.idCheckboxes()) {
        target.closest(this.containerValue).classList.remove("id-checkbox--shift-hover");
      }
    }
  }

  _shiftTargets(shiftCheckbox) {
    if (!this.shiftCheckboxSource) {
      return [];
    }
    let found = false;
    let targets = [];
    for (let checkbox of this.idCheckboxes()) {
      if (!found) {
        if (checkbox === shiftCheckbox) {
          found = checkbox;
          targets.push(checkbox);
        } else if (checkbox === this.shiftCheckboxSource) {
          found = checkbox;
          targets.push(checkbox);
        }
      } else {
        targets.push(checkbox);
        if (checkbox === shiftCheckbox || checkbox === this.shiftCheckboxSource) {
          return targets;
        }
      }
    }
    return targets;
  }

  addIdsCheckboxChanged() {
    this.allIdsCheckbox.classList.remove("input--indeterminate");
    let checked = this.allIdsCheckbox.checked;
    this.ensureStateOfButtons({show: checked});
    for (let checkbox of this.idCheckboxes()) {
      checkbox.checked = checked;
    }
  }

  checkAllPrespecifiedIds() {
    // if (window.location.search.indexOf('ids') > -1) {
    let searchParams = (new URL(document.location)).searchParams;
    let ids = searchParams.getAll("ids[]");
    for (let id of ids) {
      let checkbox = this.idCheckbox(id);
      if (checkbox) {
        // NB sometimes checkbox won't be displayed because e.g. completed job on workshop progress page
        checkbox.checked = true;
      }
    }
  }

  idCheckbox(id) {
    return document.getElementById(`ids-${id}`);
  }

  ensureStateOfAllIdsCheckbox() {
    let state = undefined;
    for (let e of this.idCheckboxes()) {
      if (state === undefined) {
        state = e.checked;
      } else if (state != e.checked) {
        this.allIdsCheckbox.classList.add("input--indeterminate");
        this.allIdsCheckbox.indeterminate = true;
        this.ensureStateOfButtons({show: true});
        return;
      }
    }
    this.allIdsCheckbox.indeterminate = false;
    this.allIdsCheckbox.classList.remove("input--indeterminate");
    this.allIdsCheckbox.checked = state;
    this.ensureStateOfButtons({show: state});
  }

  ensureStateOfButtons({show}) {
    // TODO: convert these to hidableTargets
    // for (let button of this.form.querySelectorAll(".batch-buttons .btn")) { // could add .batch-disablable to btn if we need this
    //   button.disabled = !show;
    //   if (show) {
    //     button.classList.remove("disabled");
    //   } else {
    //     button.classList.add("disabled");
    //   }
    // }
    // TODO: convert these to hidableTargets
    for (let hidable of this.form.querySelectorAll(".batch-buttons.hidable,.batch-buttons .hidable")) {
      if (show) {
        hidable.style.display = "inline-block";
      } else {
        hidable.style.display = "none";
      }
    }
  }

  idCheckboxes() {
    return this.form.querySelectorAll("input[type=checkbox][name='ids[]']");
  }

  submitViaTurbolinks(event) {
    // TODO: possibly convert this from an action to using the hidableTargets when we create those
    event.preventDefault();
    event.stopPropagation();
    let button = event.currentTarget;
    let name = button.getAttribute("name");
    let value = button.getAttribute("value");
    Turbolinks.visit(this.form.action + this.constructQueryStringFromForm({name, value}));
  }

  constructQueryStringFromForm({name, value}) {
    const entries = [...new FormData(this.form).entries()];
    if (name && value) {
      entries.push([name, value]);
    }
    let components = entries.map(e => e.map(encodeURIComponent).join("="));
    return "?" + components.join("&");
  }
}
